import { TitleMonthContainer } from '../../AdminMonthlyTasks/styles';
import styled ,{ css } from "styled-components";
import {  DropdownButton, DropdownItem, Modal } from 'react-bootstrap';
const BoldFont = 'Rubik-Bold';

const TitleContainer = styled(TitleMonthContainer)`
@media (max-width: 1000px){
	width: 95%;
	padding: 15px 0px 40px 0px;
}
`;

const MainContainer = styled.div`
  padding: 25px;
  width: 100%;
  display:flex;
  .main{
    width: 100%;
    max-width: 1248px;
    margin: auto;
  }
`;

const HeadingContainer = styled.div`
	padding: ${({padding}) => padding};
  width: ${({width}) => width ? width : '100%'};
  max-width:1246px;
  margin:25px 0;
  height:70px;
  background-color: ${({background}) => background ? '#69C2FF' : 'white'};
border-radius:6px;
 display: flex;
 justify-content:flex-start;
 align-items:center;
 h1{
	margin-left:25px;
	font-size:18px;
	font-family:rubik-medium;
	color: #0D4270;

 }
  }
`;
const HeadingContainerV2 = styled.div`
	padding:0 25px;
  width: ${({width}) => width ? width : '100%'};
  max-width:1246px;
  height:${({height}) => height ? height : '70px'};
  background-color:white;
  border-bottom:1px solid #9C9C9C80;

 display: flex;
 justify-content:${({flex})=>flex?flex:'flex-start'};
 
 align-items:center;
 h1{
	
	font-size:18px;
	font-family:rubik-medium;
	color: #0D4270;

 }
  }
`;

const SaveDetailsContainer = styled.div`
	padding: ${({padding}) => padding};
  position:fixed;
  bottom:0;
  box-shadow: 0px -11px 14px 0px #0000001A;
  width: ${({width}) => width ? width : '100%'};
  height:${({height}) => height ? height : '80px'};
  background-color:white;
  display:${({hidden}) => hidden ? 'none': 'flex'};
  justify-content:center;
  align-items:center;
`;


const BasicRecognitionMainContainer = styled.div`
	padding: ${({padding}) => padding};
  width: ${({width}) => width ? width : '100%'};
  max-width:1246px;
  height:${({height}) => height ? height : '242px'};

  margin-bottom:25px;
  background-color:white;
 display:${({hidden}) => hidden ? 'none': 'flex'};
 flex-direction:column;
 border-radius:6px;
 justify-content:center;
 align-items:center;

  }
`;
const BasicRecognitionInnerContainer = styled.div`
display: flex;
 align-items:center;
 width:100%;
 height:${({height}) => height ? height : '169px'};
 padding:25px 25px 25px 0px;
flex-wrap:wrap;
>div{
  margin-bottom:${({marginBottom})=>marginBottom ? marginBottom:""};
  display: flex;
  padding:15px 15px 15px 0px;
  position:relative;
 ${'' /* justify-content:space-evenly; */}
 align-items:center;
  width: calc((100% - 75px) / 3);
	min-height:100px;
	border:1px solid #669db7;
border-radius:6px;
margin-left: 25px;
.imgDiv{
    width: 80px;
    height: 80px;
    background: rgba(156, 156, 156, 0.1);
    border-radius: 5px;
    margin:0px 15px;
  >img{
    width : 50px;
    height: 50px;
    margin: 15px;
  }
}
.rightHalf{
  height:65px;
  margin-top: -10px;
  width: 100%;
  ${'' /* width:70%; */}
color:black;
h1{
  margin:0 0 10px 0;
font-size:18px;
font-family:rubik-medium;
line-height:20px;
font-weight:500;
color:#005c87;
}
.edit-btn{
  margin-bottom: 12px;
  margin-left: 10px;
  cursor: pointer;
}
p{
color: var(--navy_blues_60, rgba(0, 92, 135, 0.60));
font-family: Rubik;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
}
h2{
  margin:0;
  ${'' /* width:202px; */}
font-size:12px;
font-family:rubik;
font-style:normal;
font-weight:400;
color:#5f92ab;
line-height:16px;

}
}
}
`

const ToggleButton=styled.div`

  position:absolute;
  width:39px;
  height:20px;
  right:15px;
  top:15px;
  .switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height:100%;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b3cfdb;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 23px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #76ab78;
  }
    
  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }
  
  / Rounded sliders /
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }



`

const CoreValueButton=styled.div`
width: ${({width}) => width ? width : '400px'};

height:50px;
display:${({hidden}) => hidden ? 'none': 'flex'};
cursor: pointer;
background-color:${({bgColor}) => bgColor ? bgColor: '#9FC989'};
text-align:center;
align-items:center;
justify-content:center;
color:white;
font-size:18px;
font-family:rubik-medium;
font-weight:500;
border-radius:3px;
`;

const StyledModalV2 = styled(Modal)`
  .modal-body{
    padding: 0px;
  }
	.modal-dialog{
		display: flex;
    justify-content: center;
    margin: 0px auto;
    transform: ${({transform}) => transform && 'translate(0, 50vh) translate(0, -50%) !important'};
		.modal-content{
		  width: 600px;
      height: auto;
          border-radius: 10px;
          border: none;
		  .modal-header {
        div > .modal-title{
          text-align: left;
          color: white;
          width: 80%;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          font-family: Rubik-Medium;
        }
				border-bottom: ${({noBorder}) => noBorder ? noBorder : '1px solid #e5e5e5'};
                background: linear-gradient( 
                180deg,#52A8ED 0%,#8AD3F7 100%);
                padding: 13px;

				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
			.modal-header .close {
				outline: none;
        float: right;
        margin-top: -21px;
        margin-right: 2px;
       color: white;
       font-size: 44px;
        opacity: 0.9;
			}
		}
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const StyledTitleV2 = styled(Modal.Title)`
  font-family: Rubik-Medium;
  text-align: left;
  color: white;
  width: 80%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  >div{
    display: flex;
    justify-content: flex-end;
    margin-top: 12.48px;
    margin-right: 12.44px;
  >img{
    width: 20px;
    height: 20px;
  }
}
  font-family: Rubik-Medium;
`;

const ClosePopUpV2 = styled.div`
width: 50%;
display: flex;
justify-content: flex-end;
margin:auto;
>img{
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}
`;

const StyledBodyV2 = styled(Modal.Body)`
  padding: 0;
  margin-left: 25px;
  margin-right: 25px;
  >div{
    .outer-box{
      width: 100px;
      height: 100px;
      background: rgba(156, 156, 156, 0.1);
      border-radius: 5px;
      margin: 25px 30px 0px 0px;
    .recognise-icon{
      margin: 14.29px;
      width: 71.43px;
      height: 71.43px;
    }
  }
    .name-box-container{
    .top-head{
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      align-items: center;
      color: #005c87;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    .border-box{
      box-sizing: border-box;
      width: 420px;
      height: 50px;     
      background: rgba(156, 156, 156, 0.05);
      border: 1px solid #649bb5;
      border-radius: 6px;

      font-family: 'Rubik-Regular';
      font-weight: 400;
      padding: 15px;
      color: #005c87;
      font-size: 18px;
      line-height: 24px;
    }
  }
  }
  .description-box{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .description-head{
      font-family: 'Rubik';
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #005c87;
    }
    .text-calc{
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #85c0ea;
      
    }
  }
  .description-add-box{
    box-sizing: border-box;
    width: 550px;
    height: 165px;
    background: rgba(156, 156, 156, 0.05);
    border: 1px solid #649bb5;
    border-radius: 6px;
    margin-top: 15px;
    margin-bottom: 21px;

    font-size: 16px;
    line-height: 20px;
    color: #649bb5;
    font-family: 'Rubik-Regular';
      padding-top: 15px;
      padding-left: 15px;
    ::placeholder {
  color: #649bb5;
  opacity: 1; /* Firefox */
}
  }
  .save-btn{
    width: 100%;
    height: 50px;
    background: ${({bgColor}) => bgColor ? '#005c87' : '#b3cfdb'};
    border-radius: 5px;
    /* margin-bottom: 24px; */
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: ${({active})=> active ? 'pointer' : 'not-allowed' };
    border: none;
    .save-text{
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
    }
  }
`;

/* const DropDownTitleContainer = styled.div`
  float: left;
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '15px 0px 10px 0px'};
  margin: ${({ margin }) => margin};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ fullWidth }) => fullWidth ? "100%" : "80%"}
    height: 200px;
    overflow-y: auto;
    position:absolute;
 >.active>a:hover {color:black}
  }

  .dropdown-menu>.active>a{
    background:#0D4270;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`; */

const DropDownTitleContainer = styled.div`
  float: ${({ float }) => float ? float : 'left'};
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '15px 0px 10px 0px'};
  margin: ${({ margin }) => margin};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    background:#F7F5EB80;
    color:#005C87;
    border: 1px solid #005C874D;
    background:rgba(156,156,156,.04);
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    background:#F7F5EB80;
    border: 1px solid #005C874D;
  }
  > div {
    float: ${({ float }) => float ? float : 'left'};
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ fullWidth, widthValue }) => widthValue ? widthValue : fullWidth ? "100%" : "70%"}
    height: ${({ height }) => height ? height : '200px'};
    overflow-y: auto;
    position:absolute;
    position: ${({ position }) => position ? "relative" : "absolute"};
    padding: 0 15px;
    border: 1px solid #005C874D;
    padding:5px 15px;
    ::-webkit-scrollbar {
      width: 7px;
      height: 45px;
    }
    ::-webkit-scrollbar-thumb {
      background: #005C8799;
      cursor: pointer;
      border-radius: 2.5px;
    }
  
    ::-webkit-scrollbar-track {
      background: #005C870D;
      border-radius: 2.5px;
    }
    >li
      >a{
      display:flex;
      color: #005C8799;
      font-family:"Rubik";
      font-size:14px;
      line-height:20px;
      padding: 3px 0 3px 15px;
      }
      >.active>a:hover {
        color: #005C87;
        font-family:"Rubik-medium";
        font-size:14px;
        line-height:20px;
      }
    }

  .dropdown-menu>.active>a{
    background:#005C870D;
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    padding: 3px 0 3px 15px;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
    }
  }
  .dropdown-menu>.active>a:hover {
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    background:#F7F5EB80;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const FieldTitle = styled.span`
  font-size: 16px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 16.5px
  color: #005C87;
  font-family: 'Rubik';
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
  >span{
    color:#F6797D;
    font-size: 18px;
  }
`;

const DropdownArrow = styled.img`
float: right;
top:${({ drop, top }) =>top?top: drop ? "67px" : " 60px"}
position: absolute;
right: ${({right}) =>right ? right : '15px'};
display:block
z-index:5
`;

const ActivityDropdown = styled(DropdownButton)`
  // padding: ${({paddingValue}) => paddingValue? paddingValue : '0px 20px'};
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  color: ${({notActive}) =>notActive? '#005C8799' : '#005C87'};
  font-size:16px;
  font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
  border: 1px solid #005C874D;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:rgba(156,156,156,.04);
  border-radius: ${({bRadius}) => bRadius ? bRadius : "0px"};
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background:rgba(156,156,156,.04);
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
    width: 100%;
    background: none;
    color: rgb(0, 92, 135);
    border: none;
    position: relative;
    text-align: left;
    height: 100%;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
  button{
    width: 100%;
    background: none;
    // color: rgb(0, 92, 135);
    border: none;
    position: relative;
    text-align: left;
    height: 100%;
    background-color: #fff;
    color: ${({fieldColor}) => fieldColor ? fieldColor : 'rgb(0, 92, 135)'}; // rgb(0, 92, 135);
    border-radius: ${({bRadius}) => bRadius ? bRadius : "0px"};
    font-size: ${({fSize}) => fSize ? fSize : ""};
    font-family: ${({fFamily}) => fFamily ? fFamily : ""};

    &:hover, &:focus:visited, &:focus-visible{
      background-color: #fff;
      color: ${({fieldColor}) => fieldColor ? fieldColor : 'rgb(0, 92, 135)'}; // rgb(0, 92, 135);
    }
    ::after{
      display: none;
    }
    &:active:focus{
      background-color: #fff !important;
      color: ${({fieldColor}) => fieldColor ? fieldColor : 'rgb(0, 92, 135)'}; // rgb(0, 92, 135) !important;
    }
  }
  .show.btn{
    background-color: #fff !important;
    color: ${({fieldColor}) => fieldColor ? fieldColor : 'rgb(0, 92, 135)'}; // rgb(0, 92, 135) !important;
  }

  .dropdown-menu.show{
    a{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0px;
      border-bottom: ${ ({isAddCoreValues}) => !isAddCoreValues && '1px solid rgba(0, 92, 135, 0.1)'};
      color: rgb(0, 92, 135);
      font-size: 14px;
      line-height: 20px;
      display: flex;
      color: rgba(0, 92, 135, 0.6);
      font-family: Rubik-medium;
      font-size: 14px;
      line-height: 20px;
      padding: 3px 0px 3px 15px;

      img{
        width: 16px;
      }

      &:hover{
        text-transform: capitalize;
        background: rgba(0, 92, 135, 0.05);
        color: rgb(0, 92, 135);
      }      
    }

    color: rgba(0, 92, 135, 0.6);
    font-family: Rubik;
    font-size: 14px;

    .active {
      background: rgba(0, 92, 135, 0.05);
      color: rgb(0, 92, 135);
      font-family: Rubik-medium;
      font-size: 14px;
      line-height: 20px;
      padding: 3px 0px 3px 15px;
    }

    .a{
      text-transform: capitalize;
      padding: 5px 0px;
      border-bottom: 1px solid rgba(0, 92, 135, 0.1);
      width: 100% !important;
      border: 2px solid black;
    }
  }
  .menuItem{
    padding: 5px 0px;
    border-bottom: 1px solid rgba(0, 92, 135, 0.1);
    a{
      padding: 3px 15px 3px 15px !important;
    }
  }
`;
const CustomMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  padding: 5px 0 !important;
  border-bottom: 1px solid #005C871A;
  text-transform: capitalize;
  width: 100% !important;
  &:last-child{
    border:none;
  }
  .tick-img{
    width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
      display:none;

  }

  &:hover .tick-img{
        width:16px;
        height:16px;
        display: flex;
        margin: auto;
        margin-right: 15px;

  }
  > a {
    width:100%;
    display: flex;
    align-items: center;
    padding: 3px 0px 3px 15px;
    color:#005C87;
    font-size: 14px; 
    line-height: 20px;
    color: rgba(0, 92, 135, 0.6);
    font-family: Rubik-medium !important;
    font-size: 14px;
    line-height: 20px;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
      display:none;
    }
    &:active, &:hover, &:focus {
      outline: none;
      color:#005C87!important;
      background-color:#005C870D!important;
      border-radius:6px !important;
      font-family: Rubik-Medium !important;
      font-size:14px;
      line-height:20px;
      padding: 3px 0px 3px 15px;
      .tick-img{
        width:16px;
        height:16px;
        display: flex;
        margin: auto;
        margin-right: 15px;

  }
      >img{
        width:16px;
        height:16px;
        display: flex;
        margin: auto;
        margin-right: 15px;
      }
    }
    &:hover{
      border: 1px solid yellow;
      color: rgb(0, 92, 135) !important;
      background-color: rgba(0, 92, 135, 0.05) !important;
      border-radius: 6px !important;
      font-family: Rubik-Medium !important;
    }
  }
`;

const CoreValueWrapper = styled.div`
  width:100%;
  display:flex;
  padding:12px;
  background:white;
  border-radius:6px;
  flex-wrap:wrap;
  .heading{
    color:#005C87;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width:100%;
    padding-bottom:12px;
    border-bottom: 1px solid rgba(0, 92, 135, 0.10);
  }

  .wrapper{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    gap:12px;
    padding-top:20px;
  }
`;

const CoreValueCard = styled.div`
width: 400px;
padding:12px;
display:flex;
background:white;
border-radius:6px;
border: 1px solid rgba(0, 122, 177, 0.30);
position:relative;
.image{
  width:60px;
  height:60px;
  background: ${({background}) => background ? background : "#002f47"};
  display:flex;
  border-radius:3px;
  >img{
    width:36px;
    height:36px;
    display: flex;
    margin: auto;
    align-items: center;
  }
}
.text{
  padding-left:12px;
  color: #005C87;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display:flex;
  margin:auto auto auto 0px;
}
`;

const HoverBackground = styled.div`
	position: absolute;
	top: 0px;
	width: 400px;
	height: 84px;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	z-index: 2;
	left: 0px;
	.view-button {
		font-family: Rubik-Medium;
		font-size: 18px;
		line-height: 24px;
		min-width: 160px;
		width: 140px;
		height: 48px;
		background: #007AB1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 4px;
		color: white;
		text-align: center;
		padding: 12px 20px 12px 20px;
	}
  .edit-photo{
    font-family: Rubik-Medium;
		font-size: 18px;
		line-height: 24px;
		min-width: 52px;
		width: 52px;
		height: 48px;
		background: #007AB1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 4px;
		color: white;
		text-align: center;
		padding: 12px 0px 12px 0px;
    margin-left: 16px;
  }
`

export {CustomMenuItem,ActivityDropdown,DropdownArrow,DropDownTitleContainer,FieldTitle,TitleContainer,MainContainer,HeadingContainer,BasicRecognitionMainContainer,
  BasicRecognitionInnerContainer,CoreValueButton, HeadingContainerV2,SaveDetailsContainer,ToggleButton, StyledModalV2, StyledTitleV2, ClosePopUpV2, StyledBodyV2, CoreValueWrapper, CoreValueCard, HoverBackground};